import GoldStarsWithBGIcon from "../assets/GoldStarsWithBGIcon";

export const SectionTwo = [
	{
		icon: <GoldStarsWithBGIcon />,
		text: "5 guaranteed job interviews",
	},
	{ icon: <GoldStarsWithBGIcon />, text: "5 guaranteed job interviews" },
];

export const FAQS = {
	header: "Frequently Asked Questions",
	data: [
		{
			title: "Are online degrees better than on-campus degrees?",
			text: "An online degree from a NAAC A+ accredited university holds the same value as a traditional on-campus degree, with added benefits of flexibility, affordability, and convenience. Study at your own pace from anywhere, all at 70% lower costs. With a wide range of programs and access to global networking opportunities, online degrees eliminate commuting and campus expenses while offering career-boosting potential. Whether you're a fresher, student, or seasoned professional, an online degree is the smarter way to advance your career.",
		},
		{
			title: "What is apna Advantage?",
			text: "apna Advantage is a premium offering designed to advance your career. It offers UGC-approved online degrees for freshers, students, and professionals, along with exclusive benefits like direct HR connections at top companies (upon degree completion), free certification courses worth ₹1 Lakh, and interview preparation.",
		},
		{
			title: "What is the cost of apna Advantage?",
			text: "apna Advantage is included at no extra cost when you purchase an online degree through our platform. We offer flexible EMI options for all our online degrees to make education more affordable. Register now to explore various payment plans.",
		},
		{
			title: "How will apna Advantage help me?",
			text: "apna Advantage enhances your career prospects by providing the same opportunities as a traditional degree, with the added benefit of direct HR connections at top companies like Google, Uber, etc. Additionally, you’ll receive certifications worth ₹1 Lakh and 360 degree job interview preparation using tools like AI resume builder and mock interview practice.",
		},
		{
			title: "How can I redeem apna Advantage?",
			text: "Once you’ve registered for admission, our team will send you an email confirmation with a unique registration number and all the details of your apna Advantage offering. Please follow that to redeem apna Advantage.",
		},
	],
};

export const ADVANTAGE_TESTIMONIALS = [
	{
		imageUrl:
			"https://cdn.apna.co/apna-learn/Testimonial/Webp%20images/Images%202/image-3-ezgif.com-jpg-to-webp-converter.webp",
		name: "Pritam Datta",
		designation: "Online MBA",
		heading: "Learning at my own pace with an online MBA",
		description:
			"As a recent graduate, I wanted to build a strong career foundation but was hesitant about the cost and time commitment of an on-campus MBA. An online MBA turned out to be the perfect solution, offering flexibility to learn at my own pace while still enjoying part-time jobs and hobbies. The program has equipped me with critical thinking and problem-solving skills that I’m already applying, even before landing a full-time role. It’s been a great investment for my future.",
	},
	{
		imageUrl:
			"https://cdn.apna.co/apna-learn/Testimonial/Webp%20images/Images%202/image-1-ezgif.com-jpg-to-webp-converter.webp",
		name: "Rohini Pal",
		designation: "Online BCA",
		heading: "Balancing growth with online BBA",
		description:
			"The online BCA program has allowed me to balance my studies with my hobbies and a part-time job. I can set my own schedule, which has helped me gain real-world experience while earning an income. The flexibility of the program makes it both educational and fulfilling, and I’m excited for the future.",
	},
	{
		imageUrl:
			"https://cdn.apna.co/apna-learn/Testimonial/Webp%20images/Images%202/image-2-ezgif.com-jpg-to-webp-converter.webp",
		name: "Mahima Sinha",
		designation: "Online MBA",
		heading: "Shaping my career with online MBA",
		description:
			"After gaining work experience post-graduation, I realized I needed further education to grow in my career. The online MBA program gave me the flexibility to study while working full-time, enhancing my leadership skills and decision-making without taking a career break. It’s been a game-changer for my professional growth.",
	},
	{
		imageUrl:
			"https://cdn.apna.co/apna-learn/Testimonial/Webp%20images/Images%202/image-ezgif.com-jpg-to-webp-converter.webp",
		name: "Cherish",
		designation: "Online BCA",
		heading: "Building my career in tech with online BCA",
		description:
			"The online BCA program provided a solid foundation in computer science. With flexible scheduling, I was able to balance my studies and other commitments. The program gave me confidence and the skills needed to pursue a career in IT, and I highly recommend it to anyone starting in the tech field.",
	},
];

export const WHY_CHOOSE_APNA_STATS = [
	{ count: "7 Lakh+", label: "hiring partners" },
	{ count: "1 Lakh+", label: "job opportunities" },
	{ count: "40 Cr+", label: "interviews completed" },
	{ count: "100%", label: "job assistance" },
];

interface Advantage {
	id: number;
	title: string;
	subTitle: string;
	coloredTitle?: string; // Optional for flexibility
}

export const APNA_ADVANTAGES: Advantage[] = [
	{
		id: 187,
		title: "Direct HR Connections",
		subTitle:
			"Connect with 7 Lakh+ HRs and recruiters from 4 Lakh+ companies that are hiring now.",
		coloredTitle: "HR Connections",
	},
	{
		id: 186,
		title: "Ace Your Interviews with Ease",
		subTitle:
			"Boost your career with tailored guidance, resume upgrades, and AI-powered mock interviews to land jobs.",
		coloredTitle: "Interviews with Ease",
	},
	{
		id: 185,
		title: "Future-Ready Upskilling Courses",
		subTitle:
			"Get ₹1 Lakh+ worth of free courses to build real-world AI projects and advance your career.",
		coloredTitle: "Upskilling Courses",
	},
];

export const OTHER_PLATFORM_ADVANTAGES: Advantage[] = [
	{
		id: 117,
		title: "Limited Placement Assistance",
		subTitle:
			"Placement support is available but with limited opportunities and guidance.",
	},
	{
		id: 119,
		title: "One-Size-Fits-All Career Advice",
		subTitle:
			"Get general career advice with limited resources and fewer job connections.",
	},
	{
		id: 118,
		title: "No Bonus Learning Support",
		subTitle: "No extra certifications or real-world AI projects for career growth.",
	},
];

export const ADVANTAGE_SESSION_STORAGE_KEYS = {
	DOWNLOAD_BROCHURE_LINK: "downloadBrochureLink",
	ADVANTAGE_COURSE_LINK: "advantageCourseLink",
	DOWNLOAD_BROCHURE_ENABLED: "downloadBrochure",
	SHOW_INTRODUCTION_SCREEN: "introductionScreenShown",
};

export const QUERY_PARAMS = {
	FORM: "isFormOpen",
	OTP: "isOtpSection",
};

export const ADVANTAGE_LEAD_MOBILE_NUMBER = "ADVANTAGE_LEAD_MOBILE_NUMBER";

export const PAGE_SOURCE = {
	MOBILE: "isMobileView",
	WEB_VIEW: "isWebView",
	DEFAULT: "default",
};

export const APPLICATION_STATUS = {
	NOT_STARTED: "form_not_started",
	COMPLETE: "long_form_completed",
	INCOMPLETE: "long_form_incomplete",
	ADMISSION_DONE: "admission_done",
} as const;

export const COMPANY_LOGOS = [
	{
		id: 2,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_jain_university_online.png",
		slug: "jain-university-online",
	},
	{
		id: 1,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_amity_university_online.png",
		slug: "amity-university-online",
	},
	{
		id: 3,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_lovely_professional_university_lpu_online.png",
		slug: "lovely-professional-university-online",
	},
	{
		id: 4,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_chandigarh_university_online.png",
		slug: "chandigarh-university-online",
	},
	{
		id: 17,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_manipal_university_online.png",
		slug: "manipal-university-online",
	},
	{
		id: 18,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_sharda_university_online.png",
		slug: "sharda-university-online",
	},
	{
		id: 19,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_dy_patil_university_online.png",
		slug: "dy-patil-university-online",
	},
	{
		id: 20,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_amrita_university_online.png",
		slug: "amrita-university-online",
	},
	{
		id: 21,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Adj_vignan_university_online.png",
		slug: "vignan-university-online",
	},
	{
		id: 1787,
		companyLogoUrl:
			"https://cdn.apna.co/apna-learn/College%20Logos/Parul%20university.jpg",
		slug: "parul-university-online",
	},
];

export const UG_DEGREES = ["BBA", "BA", "B.Com", "BCA"];
export const PG_DEGREES = ["MBA", "M.Com", "MCA", "MA", "M.Sc"];
